import $ from 'jquery';


var detectTouchSwipe=function(el, nextCb, prevCb) {
  var startPoint=null;
  var endPoint=null;
  var treshold=40;
  $(el).off("touchstart").on("touchstart", function(e) {
    if (e.originalEvent.touches.length==1) {
      startPoint=[e.originalEvent.touches[0].clientX,
                  e.originalEvent.touches[0].clientY];
    }
  });
  $(el).off("touchmove").on("touchmove", function(e) {
    if (e.originalEvent.touches.length==1) {
      endPoint=[e.originalEvent.touches[0].clientX,
                e.originalEvent.touches[0].clientY];
    }
  });
  $(el).off("touchend").on("touchend", function(e) {
    if (e.originalEvent.touches.length!=0)
      return;
    if (!startPoint || !endPoint)
      return;
    var angle=Math.abs(endPoint[0]-startPoint[0])>Math.abs(endPoint[1]-startPoint[1]);
    if (endPoint[0]<startPoint[0]-treshold && angle)
      nextCb && nextCb();
    if (endPoint[0]>startPoint[0]+treshold && angle)
      prevCb && prevCb();
    startPoint=null;
    endPoint=null;
  });
}


// button animation

var svgNs="http://www.w3.org/2000/svg";
var progressLineWidth=4;
var progressLineRadius=12;
var animationDuration=2;

$(function($) {
  var animateProgress=function(el) {
    var animationDuration=el.attr("data-animduration");
    el.find("svg").remove();
    el.find(".checkmark").remove();
    var svg=document.createElementNS(svgNs, "svg");
    svg.setAttribute("class", "progress-line");
    svg.setAttribute("width", el.innerWidth()+progressLineWidth*4);
    svg.setAttribute("height", el.innerHeight()+progressLineWidth*4);
    
    var path=document.createElementNS(svgNs, "path");
    
    var radius=progressLineRadius+progressLineWidth/2;
    var offset=progressLineWidth/2;
    var shapeWidth=el.innerWidth()+progressLineWidth*2;
    var shapeHeight=el.innerHeight()+progressLineWidth*2;
    
    path.setAttribute("d", 
      `M ${offset} ${offset+shapeHeight/2} `+
      `L ${offset} ${offset+radius} `+
      `A ${radius} ${radius} 0 0 1 ${offset+radius} ${offset} `+
      `L ${offset+shapeWidth-radius} ${offset} `+
      `A ${radius} ${radius} 0 0 1 ${offset+shapeWidth} ${offset+radius} `+
      `L ${offset+shapeWidth} ${offset+shapeHeight-radius} `+
      `A ${radius} ${radius} 0 0 1 ${offset+shapeWidth-radius} ${offset+shapeHeight} `+
      `L ${offset+radius} ${offset+shapeHeight} `+
      `A ${radius} ${radius} 0 0 1 ${offset} ${offset+shapeHeight-radius} `+
      `Z`);
    
    svg.appendChild(path);
    el.append(svg);
    
    var pathLength=path.getTotalLength();
    var progress=0;
    path.setAttribute("stroke-dasharray", (progress*pathLength)+" "+pathLength);
    
    var start=(new Date()).getTime();
    var animationInterval=setInterval(function() {
      var now=(new Date()).getTime();
      progress=(now-start)/1000/animationDuration;
      path.setAttribute("stroke-dasharray", (progress*pathLength)+" "+pathLength);
      if (progress>=1) {
        clearInterval(animationInterval);
        svg.setAttribute("class", "progress-line done");
      }
    }, 16);
    
  }

  $(".form-contact").submit(function(e) {
    var $this=$(this);
    var el=$this.find("button.progress-button");
    animateProgress(el);
    var animationDuration=el.attr("data-animduration");
    if ($this.attr("data-action")) {
      e.preventDefault();
      $.post($this.attr("data-action"), $this.serialize(), function(data) {
        $this.closest("form").removeClass("submitted")[0].reset();
        if (el.attr("data-done")) {
          el.find("span").html(el.attr("data-done"));
        }
      }, "json");
    }
  });
  
  $(".form-contact button").click(function(e) {
    $(this).closest("form").addClass("submitted");
  });
  
  
  $(".form-field").each(function() {
    var $el=$(this);
    $el.find("input, textarea").on("input", function(e) {
      if ($(this).val())
        $el.addClass("filled");
      else
        $el.removeClass("filled");
    }).trigger("input");    
  });
  
});


$(function($) {
  $("nav .search a").click(function(e) {
    e.preventDefault();
    $(".mobile-nav").removeClass("open");
    $("header").removeClass("menu-open");

    $(this).closest(".search").toggleClass("open");
    $(".popup-search").toggleClass("open");
    if ($(".popup-search").hasClass("open")) {
      setTimeout(()=>{
        $(".popup-search [autofocus]").focus();
      }, 30);
      $("html").css("overflow", "hidden");
    } else {
      $("html").css("overflow", "");
    }
  });
  $(".popup-search form").on("reset", function() {
    window.artShowSearchResults([]);
  });
  window.artShowSearchResults=function(results) {
    var resultsUl=$(".popup-search .results");
    resultsUl.html("");
    for(var result of results) {
      var resultLi=$("<li />").appendTo(resultsUl);
      var resultA=$("<a />", {href: result.href}).appendTo(resultLi);
      var resultSection=$("<span />", {class: "section", html: result.section}).appendTo(resultA);
      var resultTitle=$("<span />", {html: result.title}).appendTo(resultA);
    }
  }
});

$(function($) {
  $("[data-video-popup]").click(function(e) {
    e.preventDefault();
    var iframe=$("<iframe />", {
      src: $(this).attr("href"),
      frameborder: "0",
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowfullscreen: "allowfullscreen"
    });
    $(".popup-video .video").html("").append(iframe);
    $(".popup-video h2").html($(this).attr("data-title"));
    $(".popup-video").addClass("open");
  });
  var photoPopups=$("[data-photo-popup]");
  var photoIndex=0;
  photoPopups.click(function(e) {
    e.preventDefault();
    photoIndex=photoPopups.index(this);
    var img=$("<img />", {src: $(this).attr("href")});
    $(".popup-photo .photo").html("").append(img);
    $(".popup-photo h2").html($(this).attr("data-title"));
    $(".popup-photo").addClass("open");
  });
  $(".popup-photo .nav-prev").click(function() {
    var oldPhotoUrl=photoPopups.eq(photoIndex).attr("href");
    photoIndex=(photoIndex+photoPopups.length-1)%photoPopups.length;
    if (photoPopups.eq(photoIndex).attr("href")==oldPhotoUrl)
      photoIndex=(photoIndex+photoPopups.length-1)%photoPopups.length;
    photoPopups.eq(photoIndex).click();
  });
  $(".popup-photo .nav-next").click(function() {
    var oldPhotoUrl=photoPopups.eq(photoIndex).attr("href");
    photoIndex=(photoIndex+1)%photoPopups.length;
    if (photoPopups.eq(photoIndex).attr("href")==oldPhotoUrl)
      photoIndex=(photoIndex+1)%photoPopups.length;
    photoPopups.eq(photoIndex).click();
  });
  detectTouchSwipe($(".popup-photo"), function() {
    $(".popup-photo .nav-next").click();    
  }, function() {
    $(".popup-photo .nav-prev").click();
  });
});

$(function($) {
  $(".popup a.close").click(function(e) {
    e.preventDefault();
    $(".popup-video .video, .popup-photo .photo").html("");
    $(".popup").removeClass("open");
  });
});

$(function($) {
  $("header .language a, .mobile-nav .language a").click(function(e) {
    e.preventDefault();
    $("header .language, .mobile-nav .language").toggleClass("open");
  });
  $("header .language, .mobile-nav .language").click(function(e) {
    e.stopPropagation();
  });
  $(document).click(function(e) {
    $("header .language, .mobile-nav .language").removeClass("open");
  });
  $(".menu-handle").click(function(e) {
    e.preventDefault();
    $("nav .search, .popup-search").removeClass("open");

    $(".mobile-nav").toggleClass("open");
    $("header").toggleClass("menu-open");
    if ($(".mobile-nav").hasClass("open")) {
      $("html").css("overflow", "hidden");
    } else {
      $("html").css("overflow", "");
    }
  });
  $("footer .container .lower-sect .back-to-top").click(function(e) {
    e.preventDefault();
    $("html,body").animate({scrollTop: 0});
  });
});

// # ANIMATIONS
$(function($) {
  // ## header
  var findHeaderEls=function() {
    var result=[];
    $("header").each(function() {
      var $this=$(this);
      $this.find(".container").append($("<div />", {class: "animoverlay"}));
      result.push($this);
    });
    return result;
  }
  
  
  // ## heading elements
  var findHeadingEls=function() {
    var result=[];
    $(".head-pic h1, .business-seg h1, .services-sect h3,"+
      ".page-content h1, .certificates-list h1, .production-list h1,"+
      ".history h1, .contact-map h1, .contact-form h1, .page-content h2,"+
      ".contact-team h1"
    ).each(function() {
      var $this=$(this);
      var words=$(this).html().trim().replace(/[ \n]+/g, " ").split(" ");
      words=words.map((word)=>
        "<span class=\"word\">"+word.split("").map((letter,index)=>
          "<span class=\"letter l"+index+"\"><span>"+letter+"</span></span>")
        .join("")+"</span>");
      $this.html(words.join(" "));
      result.push($this);
    });
    return result;
  }
  
  // ## photos
  var findPhotos=function() {
    var result=[];
    $(".head-pic .bg-pic img, .box-container>img, .head-pic .bg-pic video,"+
      ".production-list img, .services-sect li img",
    ).each(function() {
      if ($(this).closest(".business-seg").length)
        return;
      $(this).wrap($("<span />", {class: "imganim"}));
      if ($(this).closest(".services-sect"))
        return $(this).closest("span").addClass("animate-done");
      result.push($(this).parent());
    });
    return result;
  }
  
  // ## segments
  var findSegmentEls=function() {
    var result=[];
    $(".business-seg .box-container,"+
      ".services-sect ul>li,"+
      "footer .container .partneship,"+
      "footer .container .lower-sect>div,"+
      ".breadcrumbs ul,"+
//       ".page-content p, .page-content li,"+
      ".production-list>div>ul>li,"+
      ".certificates-list>div>ul>li,"+
      ".history-container,"+
      ".contact-map .map-container .map,"+
      ".contact-map address,"+
      ".contact-map .contact,"+
      ".contact-form .form-field, .contact-form .actions,"+
      ".not-found>.container>*,"+
      ".page-content .table-container,"+
      ".back-left, .team li"
     ).each(function() {
      result.push($(this));
    });
    return result;
  }
  
  // ## common
  var $window=$(window);
  var animationEls=[];
  var animationQueue=[];
  for(var el of findHeaderEls())
    animationEls.push({el, type: "header", delay: 0});
  for(var el of findHeadingEls())
    animationEls.push({el, type: "heading", delay: 100});
  for(var el of findPhotos())
    animationEls.push({el, type: "photo", delay: 100});
  for(var el of findSegmentEls())
    animationEls.push({el, type: "segment", delay: 100});

  animationEls.sort((a,b)=>{
    if ((a.el.offset() || {}).top<(b.el.offset() || {}).top) return -1;
    if ((a.el.offset() || {}).top>(b.el.offset() || {}).top) return 1;
    return 0;
  });
  
  animationQueue.addToQueue=function(et) {
    animationQueue.push(et);
    if (animationQueue.length==1)
      animationQueue.animateNext();
  }
  animationQueue.animateNext=function() {
    if (!animationQueue.length)
      return;
    doAnimateEl(animationQueue[0]);
    var delay=animationQueue[0].delay;
    if (animationQueue[0].el.offset().top+animationQueue[0].el.innerHeight()<
        $(window).scrollTop())
      delay=0;
    setTimeout(()=>{
      animationQueue.shift();
      animationQueue.animateNext();
    }, delay);
  }
  var doAnimateEl=function({el, type}) {
    el.addClass("animate");
    setTimeout(()=>el.removeClass("animate").addClass("animate-done"), 1000);
  }
  $window.on("scroll.scanim", function() {
    var scrollTop=$window.scrollTop();
    var windowHeight=$window.innerHeight();
    var elsToKeep=[];
    for(var et of animationEls) {
      var elTop=et.el.offset().top;
      if (elTop>=scrollTop+windowHeight) {
        elsToKeep.push(et);
      } else {
        animationQueue.addToQueue(et);
      }
    }
    animationEls=elsToKeep;
  }).trigger("scroll.scanim");
});



// # History animation
$(function($) {
  var years=$(".hist-years li");
  var items=$(".hist-items li");
  years.removeClass("active").eq(0).addClass("active");
  items.removeClass("active").eq(0).addClass("active");
  var currentIndex=0;
  years.click(function(e) {
    e.preventDefault();
    updateIndex(years.index(this));
  });
  detectTouchSwipe($(".history-container"), function() {
    updateIndex((currentIndex+1)%years.length);
  }, function() {
    updateIndex((currentIndex+years.length-1)%years.length);
  });
  var updateIndex=function(newIndex) {
    var isNewer=newIndex>currentIndex;
    var diff=Math.abs(newIndex-currentIndex);
    diff=Math.pow(diff, 0.7);
    currentIndex=newIndex;
    var inactiveWidth=600;
    years.removeClass("active");
    items.removeClass("active");
    items.eq(0).css("margin-left", (currentIndex*-inactiveWidth)+"px").css("transition", "margin-left "+(.5*diff)+"s ease-out, margin-right "+(.5*diff)+"s ease-out");
    if (isNewer)
      diff=1;
    setTimeout(()=>{
      years.eq(currentIndex).addClass("active");
      items.eq(currentIndex).addClass("active");
    }, (diff-1)*300);
  }
});
